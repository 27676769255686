import { Injectable } from '@angular/core';
import { NzIconService } from 'ng-zorro-antd/icon';
import {
  addToCartItem,
  billIcon,
  cartIcon,
  discountIcon,
  editIcon,
  globalIcon,
  locationIcon,
  logoutIcon,
  notiIcon,
  pointIcon,
  profileIcon,
  searchIcon,
  trashIcon,
} from './iconAntd';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(private iconService: NzIconService) {
    this.iconService.addIconLiteral('cartIcon:antd', cartIcon);
    this.iconService.addIconLiteral('notiIcon:antd', notiIcon);
    this.iconService.addIconLiteral('globalIcon:antd', globalIcon);
    this.iconService.addIconLiteral('searchIcon:antd', searchIcon);
    this.iconService.addIconLiteral('trashIcon:antd', trashIcon);

    this.iconService.addIconLiteral('billIcon:antd', billIcon);
    this.iconService.addIconLiteral('pointIcon:antd', pointIcon);
    this.iconService.addIconLiteral('discountIcon:antd', discountIcon);
    this.iconService.addIconLiteral('editIcon:antd', editIcon);
    this.iconService.addIconLiteral('locationIcon:antd', locationIcon);
    this.iconService.addIconLiteral('addToCartItem:antd', addToCartItem);
    this.iconService.addIconLiteral('profileIcon:antd', profileIcon);
    this.iconService.addIconLiteral('logoutIcon:antd', logoutIcon);
  }
}
