import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import vi from '@angular/common/locales/vi';
import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  AuthConfig,
  OAuthStorage,
  provideOAuthClient,
} from 'angular-oauth2-oidc';
import { NZ_I18N, provideNzI18n, vi_VN } from 'ng-zorro-antd/i18n';
import { routes } from './app.routes';

import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Interceptor } from 'authen-service-library';
import { QuillModule } from 'ngx-quill';
import { authCodeFlowConfig } from '../environments/auth/auth-config';
import { AgencyConfigEffects } from './core/states/agencyConfig/agencyConfig.effects';
import { metaReducers, reducers } from './store';

// const Quill: any = QuillNamespace;
// const FontSize = Quill.import('attributors/class/size');
// const Font = Quill.import('attributors/class/font');
// FontSize.whitelist = fontSize;
// Font.whitelist = font;
// Quill.register(FontSize, true);
// Quill.register(Font, true);
registerLocaleData(vi);

export function storageFactory(): OAuthStorage {
  return localStorage;
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    provideRouter(routes),
    provideNzI18n(vi_VN),
    importProvidersFrom(FormsModule),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([Interceptor])),
    provideAnimationsAsync(),
    provideOAuthClient(),
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: authAppInitializerFactory,
    //   deps: [AuthService],
    //   multi: true,
    // },
    importProvidersFrom(QuillModule.forRoot()),
    {
      provide: AuthConfig,
      useValue: authCodeFlowConfig,
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    provideStore(reducers, { metaReducers }),
    provideAnimationsAsync(),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    { provide: NZ_I18N, useValue: vi_VN },
    provideEffects(AgencyConfigEffects),
  ],
};
