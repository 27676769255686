import { HttpClient } from '@angular/common/http';
import * as i0 from '@angular/core';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom, BehaviorSubject, throwError, from } from 'rxjs';
import * as i1 from '@auth0/angular-jwt';
import * as i2 from 'angular-oauth2-oidc';
import { OAuthService } from 'angular-oauth2-oidc';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
class AuthService {
  constructor(jwtHelper, oauthService) {
    this.jwtHelper = jwtHelper;
    this.oauthService = oauthService;
    this._http = inject(HttpClient);
    this.config = {
      loginURl: '',
      refreshTokenURL: '',
      logoutURL: '',
      timeToRefreshToken: 60000,
      refreshFunctionOfInterceptor: () => {},
      logoutFunctionOfInterceptor: () => {}
    };
  }
  login(userName, password) {
    return this._http.post(this.config.loginURl, {
      username: userName,
      password: password
    });
  }
  refreshToken() {
    return this._http.post(this.config.refreshTokenURL, {
      token: localStorage.getItem('refresh_token')
    });
  }
  logout() {
    return this._http.post(this.config.logoutURL, {
      token: localStorage.getItem('refresh_token')
    });
  }
  async loginNoAuth2(userName, password) {
    const data = await lastValueFrom(this.login(userName, password));
    const userInfor = {
      ...data
    };
    delete userInfor?.token;
    delete userInfor?.refreshToken;
    localStorage.clear();
    localStorage.setItem('access_token', data?.token);
    localStorage.setItem('refresh_token', data?.refreshToken);
    localStorage.setItem('expires_at', (this.jwtHelper.decodeToken(data?.token).exp * 1000).toString());
    localStorage.setItem('userInfor', JSON.stringify(userInfor));
    return data;
  }
  async refreshTokenNoAuth2() {
    const data = await lastValueFrom(this.refreshToken());
    localStorage.setItem('access_token', data?.token);
    localStorage.setItem('refresh_token', data?.refreshToken);
    localStorage.setItem('expires_at', (this.jwtHelper.decodeToken(data.token).exp * 1000).toString());
    this.silentRefreshToken();
    return data;
  }
  // Schedule token refresh
  silentRefreshToken() {
    clearTimeout(this.idTimeOut);
    const expiresIn = this.oauthService.getAccessTokenExpiration() - Date.now() - (this.config.timeToRefreshToken || 900000); // Refresh 1m before expiration
    this.idTimeOut = setTimeout(() => {
      this.refreshTokenNoAuth2();
    }, expiresIn);
  }
  logOutNoAuth2() {
    clearTimeout(this.idTimeOut);
    this.logout().subscribe(() => {
      localStorage.clear();
    }, err => {
      console.error(err);
    });
  }
  static {
    this.ɵfac = function AuthService_Factory(ɵt) {
      return new (ɵt || AuthService)(i0.ɵɵinject(i1.JwtHelperService), i0.ɵɵinject(i2.OAuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthService,
      factory: AuthService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.JwtHelperService
  }, {
    type: i2.OAuthService
  }], null);
})();

// Đặt các biến toàn cục bên ngoài interceptor
let isRefreshing = false;
const refreshTokenSubject = new BehaviorSubject(null);
const Interceptor = (req, next) => {
  const authService = inject(AuthService);
  const OauthService = inject(OAuthService);
  const authToken = OauthService.getAccessToken();
  if (authToken) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    });
  }
  return next(req).pipe(catchError(error => {
    if (error.status === 401) {
      return handle401Error(req, next, authService);
    } else {
      return throwError(() => error);
    }
  }));
};
function handle401Error(request, next, authService) {
  if (!isRefreshing) {
    isRefreshing = true;
    refreshTokenSubject.next(null);
    return from(authService.config.refreshFunctionOfInterceptor()).pipe(switchMap(token => {
      isRefreshing = false;
      refreshTokenSubject.next(token.token);
      refreshTokenSubject.complete();
      return next(request.clone({
        setHeaders: {
          Authorization: `Bearer ${token.token}`
        }
      }));
    }), catchError(err => {
      isRefreshing = false;
      if (err.status === 401) {
        authService.logOutNoAuth2();
        localStorage.clear();
        window.location.reload();
      }
      // authService.logout(); // Tùy chỉnh việc đăng xuất người dùng nếu làm mới token thất bại
      return throwError(() => err);
    }));
  } else {
    return refreshTokenSubject.pipe(filter(token => token != null), take(1), switchMap(jwt => {
      return next(request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwt}`
        }
      }));
    }));
  }
}

/*
 * Public API Surface of auth-service
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AuthService, Interceptor };
