import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from 'authen-service-library';
import { en_US, NzI18nService, vi_VN } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../environments/environment';
import { getAgencyConfig } from './core/states/agencyConfig/agencyConfig.action';
import { agencyConfigSelector } from './core/states/agencyConfig/agencyConfig.selector';
import { IconService } from './share/icon.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NzIconModule, CommonModule, TranslateModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'Base';
  isLoginWithAuth2: boolean = false;
  endpoint = environment.API_URL_LOGIN;
  constructor(
    private oauthService: OAuthService,
    private translate: TranslateService,
    private authService: AuthService,
    private _store: Store,
    private titleService: Title,
    private mess: NzMessageService,
    private i18n: NzI18nService,
    private cdr: ChangeDetectorRef,
    private IconService: IconService
  ) {
    this.authService.config.refreshFunctionOfInterceptor = (): Promise<any> => {
      return this.oauthService.refreshToken();
    };

    if (navigator.language.includes('vi')) {
      this.i18n.setLocale(vi_VN);
      this.translate.use('vi');
    } else if (navigator.language.includes('en')) {
      this.i18n.setLocale(en_US);
      this.translate.use('en');
    }

    if (localStorage.getItem('access_token')) {
      this.authService.silentRefreshToken();
    }
  }
  config: any;
  ngOnInit(): void {
    let expires_at: any = null;
    if (localStorage.getItem('expires_at')) {
      expires_at = new Date(Number(localStorage.getItem('expires_at') || ''));
    }
    const now: any = new Date();
    if (expires_at && expires_at < now) {
      this.mess.warning('Hết phiên đăng nhập.Vui lòng đăng nhập lại');
      const u = localStorage.getItem('u');
      const p = localStorage.getItem('p');
      localStorage.clear();
      if (u && p) {
        localStorage.setItem('u', u);
        localStorage.setItem('p', p);
      }
      window.location.reload();
      // this.router.navigate(['/login']);
    }
    this._store.dispatch(getAgencyConfig());
    const sub = this._store.select(agencyConfigSelector).subscribe((data) => {
      if (!data) return;
      this.titleService.setTitle(data.shortName);
      this.updateFavicon(data.logo);
      this.config = data;
      this.cdr.detectChanges();
      sub.unsubscribe();
    });
    // this.getAgencyConfig();
  }

  refreshToken() {
    this.oauthService.refreshToken();
  }
  logout() {
    this.authService.logOutNoAuth2();
  }

  updateFavicon(iconUrl: string) {
    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = iconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}
