import { createReducer, on } from '@ngrx/store';
import { AgencyConfigInfo } from '../../models/AgencyConfig';
import * as AgencyConfigActions from './agencyConfig.action';

export interface AgencyConfigState {
  agencyConfig: AgencyConfigInfo | null;
  status: 'idle' | 'loading' | 'loaded' | 'failed';
  error?: string;
  user: {};
}

const initialState: AgencyConfigState = {
  agencyConfig: null,
  status: 'idle',
  user: {},
};

export const agencyConfigReducer = createReducer<
  AgencyConfigState,
  AgencyConfigActions.AgencyConfigTypes
>(
  initialState,
  on(AgencyConfigActions.getAgencyConfig, (state) => ({
    ...state,
    status: 'loading',
  })),
  on(AgencyConfigActions.getAgencyConfigSuccess, (state, { configInfo }) => ({
    ...state,
    agencyConfig: configInfo,
    status: 'loaded',
  })),
  on(AgencyConfigActions.getAgencyConfigFailed, (state, { error }) => ({
    ...state,
    error,
    status: 'failed',
  })),
  on(AgencyConfigActions.getAgencyConfigSuccess2, (state, { pro }) => ({
    ...state,
    user: pro,
  }))
);
