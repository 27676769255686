import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../app/layout/main/main.routing.module').then(
        (m) => m.MainRoutingModule
      ),
    // canActivate: [authGuardFn],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./features/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: '404',
    loadComponent: () =>
      import('./features/404/404.component').then((m) => m.NotFoundComponent),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];
