import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { AgencyConfigService } from '../../apis/agency-config.service';
import * as AgencyConfigActions from './agencyConfig.action';

@Injectable()
export class AgencyConfigEffects {
  _actions = inject(Actions);
  _agencyConfigService = inject(AgencyConfigService);

  fetchAgencyConfig$ = createEffect(() =>
    this._actions.pipe(
      ofType(AgencyConfigActions.getAgencyConfig),
      mergeMap(() =>
        this._agencyConfigService.getAgencyConfig().pipe(
          map((configInfo) =>
            AgencyConfigActions.getAgencyConfigSuccess({ configInfo })
          ),
          catchError((error) => {
            return of(AgencyConfigActions.getAgencyConfigFailed({ error }));
          })
        )
      )
    )
  );
}
